// import { Style } from '@material-ui/icons'
import React from 'react'
import Style from "../Styles/Home.module.scss"
import Nav from "../Nav/Nav"
import Book from "./BookingMain"

function Bookings() {
    return (
        <div><Nav/>
<h1>Bookings</h1>

        <div style={{display:"flex", flexWrap:"wrap"}}>
        <div style={{margin:"0 auto"}}>
<Book/>
            {/* <br/>
            <br/>
            <br/> */}
        </div>
            
        </div></div>
    )
}

export default Bookings
