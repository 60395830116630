import React, {useState, useEffect, useRef} from 'react';
import Title from "../assets/images/FIlmBOiAbes.png";
import Production from "../assets/images/MusicPrudiction.png";


import Style from "../Styles/Home.module.scss"

import Select from "./components/Select"
import clickSound from "../assets/Sounds/Mario-coin-sound.mp3"

import fart from "../assets/images/PressStart.png"

// import Start from "./components/Started.jsx"
import Parts from "./components/Background"

import {Link} from "react-router-dom" 

const Home = ()=> {
    let [ started, setStarted] = useState(true);

    let audio = new Audio(clickSound);

useEffect(() => {
    document.body.style.backgroundColor ="black"

    return () => document.body.style.backgroundColor ="white"
},[])

let nope = "Image not available please try refresh"


let flip;

if (started === false){
    flip= <Select/>
}
else { flip = <div className={Style.start} 
onClick={()=>{    audio.play();
    setStarted(!started)}}>
        <img src={fart} alt="not here bro"/></div>
}

    return (
        <div
        style={{
        //   backgroundColor:"black",
          position: "absolute",
          top: 0,
          left: 0,
          bottom:0,
          right:0,
          width: "100vw",
          height: "100vh",
          zIndex:0
        }}
      >
        <Parts/>
        <div
          style={{
            // backgroundColor:"black",
            position: "absolute",
            top: 0,
          right:0,
          bottom:0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex:2

          }}
        >

        <div className={Style.background} style={{display:"flex",transform:"scale(0.85)", flexWrap:"wrap"}}>
   <div style={{margin:"0 auto"}}>
<br/>
<div style={{position:"relative", top:"100px"}}>
            <img src={Title} alt={nope} className={Style.titleImage}/>
            </div>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

            <img src={Production} alt={nope}
             className={Style.vidProduction}
             />

            <div style={{display:"flex", flexWrap:"wrap"}}>
            <div style={{margin:"0 auto"}}>

<Link to="#">
<div  className={Style.flip}>
{flip}
</div></Link>


<br/>
            </div>
            </div>
            </div>
            </div>

   </div></div>
    )
}

export default Home
