import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import Intro from "./intro"
import Nav from "./Nav/Nav"
import Home from "./Home/Home.jsx" 
import Book from "./Bookings/Bookings" 
import Show from "./Showreel/ShowReel"
import Vid from "./Videos/Videos" 
import Parts from "./Home/components/Background"


function App() {

  useEffect(()=> {
    // document.body.contentEditable ="true";
    document.title = "Film Boi Abes"

    
      },[])
    
    
      return (
    
        <Router>
    
    
        <Route
       render={({location}) => {
    
        return ( 
    
      
          <div>            <Switch>

                {/* <div
        style={{
          backgroundColor:"black",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
      >
        <Parts/>
        <div
          style={{
            // backgroundColor:"black",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        > */}
 
              <Route path="/" exact component={Home} />    
              <Route path="/Bookings" exact component={Book} />    
              <Route path="/ShowReel" exact component={Show} />    
              <Route path="/Videos" exact component={Vid} />    


              {/* <Route path="/Patient/:id" exact component={PatientDetails} />     */}
    
  
            
    {/* </div>     </div>   */}
    </Switch>
    
          </div>
    
    
                )
      }}/>
    
    
        </Router>
    
      );
    }
    

export default App;
