import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import Button from "@material-ui/core/Button"
import Style from "../../Styles/Showreel.module.scss"
import Nav from "../../Nav/Nav"

function Youtubez() {

    const [video, setvideo] = useState(1)
    const [player, setPlayer] = useState(0)


    let close = (e) => {
        setvideo(2)
    }

    // const [selection, setSelection] = useState("VYKPX1W3904")
let choices = ({
Metz: "VYKPX1W3904",
HiGrade:"xnC2IO5b3u0",
MykiTuff:"gfe1lSwrljY",
})

function onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
    if (player===1) {    
        event.target.pauseVideo()
    }
  }
  let foo = 0;

  const opts = {
    // height: '1000',
    // width: '1000',
    playerVars: { 
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      start: 1,
      modestbranding:1,
      rel:0,
      fs:0,
      disablekb:1,
    //   autohide: 1,
    //   theme:'light',
      controls:foo,
    }
  };

  let selection;
  if (video ===1) {
selection = <div>     

    <div style={{display:"flex", flexWrap:"wrap"}}
    >
<Button className={Style.button}
onClick={close}> Press enter to skip</Button></div>

      <YouTube
      onStateChange={(e) => {
          if (e.data == 0){
              close()
          }
      }}
videoId='U8CJ0p131MM'
opts={opts}
className={Style.screen}

onPause={(e)=> {
    if (player===0){
foo = 1
    } else {
foo = 0
    }
}}                    // defaults -> noop

onReady={onReady}
/>
</div>
  
  } else if (video ===2) {

     selection =    <div > 
         <div className={Style.home}/>
         <div className={Style.home2}/>
         <div className={Style.home3}/>
         <div className={Style.home4}/>
         <div className={Style.home5}/>
         {/* <div style={{zIndex:"2"}} className={Style.rotate}>
         <div className={Style.ome}/>
         <div className={Style.ome2}/>
         <div className={Style.ome3}/>
         <div className={Style.ome4}/>
         <div className={Style.ome5}/></div> */}
      <div >
        
      <Nav/>
 <div style={{display:"flex", flexWrap:"wrap"}}>
     <div style={{margin:"0 auto", color:"orange"}}>
<br/>
<br/>
<br/>
         <br/>
        <h1> About</h1>

        <br/>
        <div style={{fontWeight:'bold', color:'black'}}>
Bael Graham, is a music video producer</div>
         <br/>
         <YouTube
videoId='U8CJ0p131MM'
// opts={opts}
onReady={onReady}
/>
         </div>  
         </div>  
      </div>
         </div>
  }

  const cntrlEnter = (e) => {     
            if(e.keyCode===13 ) { close(); }
                }

    useEffect(()=>{
         
            document.addEventListener('keypress', cntrlEnter );
    
            return (
           () =>{ document.removeEventListener('keypress', cntrlEnter)})
    
        },[])
 
    return (
        <div>


{selection}

        </div>
    )


}

export default Youtubez
