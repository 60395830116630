import React from 'react'
import AppBar from "@material-ui/core/AppBar"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import Stuff from "../assets/images/Face.jpg"
import {Link} from "react-router-dom";
import Style from "../Styles/Nav.module.scss";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor:"#0FBE7C",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

const Nav  = () => {

    
    const classes = useStyles();


    return (
        <div style={{backgroundColor:"#0FBE7C"}} className={classes.root}>
        <AppBar color="inherit"  position="fixed">
          <Toolbar>
          <Link style={{textDecoration:"none"}} to="/"> 
          <img src={Stuff} alt="Where's my face?" className={Style.face} 
          />
</Link>
              <Link 
              className={Style.link} 
              to="/Bookings"><Button 
              className={Style.link} 
              >Bookings</Button></Link>
              <Link
              className={Style.link} 
               to="/Showreel"><Button
               className={Style.link} 
               >Showreel</Button></Link>
              <Link
              className={Style.link} 
               to="/Videos"><Button
               className={Style.link} 
               >Videos</Button></Link>
              
   
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
    </IconButton>

  
   
            <Typography variant="h6" className={classes.title}>    
         </Typography>
{/* Abel Graham */}

          </Toolbar> 
        </AppBar>
      </div>
    )
}

export default Nav

