import React, {useState} from 'react'
import firebase from "firebase"
import Axios from "axios"
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";

    firebase.initializeApp({
        apiKey: 'AIzaSyBSKclr1knlXSfgz6gD445IRnxNT4i4sj8',
        authDomain: 'firebase.app.performance-therapist',
        projectId: 'performance-therapist'
      });

function Form() {



 let [email, setEmail] = useState('');
 let [phone, setPhone] = useState('');
 let [name, setName] = useState('');
 let [pass, setPass] = useState(false);

 let [message, setMessage] = useState('');

 const recaptchaRef = React.useRef();
 
 const onSubmitWithReCAPTCHA = async () => {
   const token = await recaptchaRef.current.executeAsync();
}

let reCapchaBoolean = () =>{
  pass? setPass(false): setPass(true); 
}
//token of errors

      const handleSubmit = event => {
        if (pass===true){
               event.preventDefault();
               

        let d = new Date();
        const data = { clientEmail:email , message: message,telephone: phone, name:name, subject: d };
        Axios.post('https://us-central1-alliancecareltd-7068f.cloudfunctions.net/submit', data)
        .catch(error => {
          console.log(error); 
        });
        alert("Message Sent");

        Axios.post('https://us-central1-alliancecareltd-7068f.cloudfunctions.net/ConfirmationEmail', data)
        .catch(error => {
          console.log(error); 
        });
        alert("Second Message Sent!");


      } else {
        alert("plase confirm you are not a bot")

      }
      };

let wide = "300px"

const phoneChange = (e) => {
  setPhone(e.target.value)}

  const nameChange = (e) => {
    setName(e.target.value)}

    const emailChange = (e) => {
      setEmail(e.target.value)}
      
      const textChange = (e) => {
        setMessage(e.target.value)}

    return (
        <div style={{display:"flex", margin:"0 auto",paddingRight:"20px", flexWrap:"wrap", maxWidth:"1000px"}}> 
            <form onSubmit={handleSubmit} style={{margin:"0 auto"}}>
  <label>

  <br />

<label>
Name:    
<br />
  <TextField fullWidth type="name" placeholder="name" variant="outlined" style={{width:wide}} name="name" value={name} onChange={nameChange} />
</label>
<br />
<label>
Telephone Number:
  <br />
  <TextField fullWidth type="tel" variant="outlined" placeholder="number" style={{width:wide}}  value={phone} onChange={phoneChange} />
</label>
<br/>
    E-mail:
    <br />
    <TextField fullWidth variant="outlined" type="email" placeholder="email" style={{width:wide}}  value={email} onChange={emailChange} />
  </label>
  <br />
  <label>
  What sort of video is it?
  <br />
    <TextField fullWidth type="text"  rows="3" placeholder="message" style={{width:wide}}  variant="outlined" multiline  value={message} onChange={textChange} />
  </label>
 
  <br />
  <Button variant="outlined" style={{color:"#0FBE7C"}} type="submit" >submit</Button>
  <br />
  <br />

  <form onSubmit={onSubmitWithReCAPTCHA}>
      <ReCAPTCHA
        ref={recaptchaRef}
        onChange={reCapchaBoolean}
        size="normal"
        sitekey="6LfPxrMZAAAAAFfPla63WpldComJAg68pnF3HN3w"
      />
    <br/>
    </form>
{/* <a style={{textDecoration:"none", fontWeight:"bold", color:"rgb(15,60,60)"}} href="https://theperformancephysio.wufoo.com/forms/z1f6lim20xycyum/"> Conscent Form </a> */}
</form>

        </div>
    )
}

export default Form
