// import { Style } from '@material-ui/icons'
import React from 'react'
import Style from "../Styles/Home.module.scss"
import Nav from "../Nav/Nav"
import Youtube from "../Videos/Youtube.jsx"


function Videos() {
    return (           
        
        <div><Nav/>

        <div style={{display:"flex", flexWrap:"wrap"}}>
        <div style={{margin:"0 auto"}}>
            <br/>
            <br/>
            <br/>
            <h1 style={{textAlign:"center"}}>Videos</h1>

            <Youtube/>
        </div>
            
        </div></div>
    )
}

export default Videos
