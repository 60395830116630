import React from 'react'
import Particles from 'react-particles-js';
 
function Background() {
    return (
       
<Particles style={{
	position:"fixed", zIndex:"0", left:0, right:0, top:0, bottom:0, width: "100vw",height:"100vh" 
}}
    params={{
	    "particles": {
	        "number": {
	            "value": 160,
	            "density": {
	                "enable": false
	            }
	        },
	        "size": {
	            "value": 2,
	            "random": true
	        },
	        "move": {
	            "direction": "right",
				"out_mode": "out",
				"random": false,
				"speed": 16,
				"straight": true,
	        },
	        "line_linked": {
	            "enable": false
	        }
	    },
	    "interactivity": {
	        "events": {
	            "onclick": {
	                "enable": false,
	                "mode": "remove"
	            }
	        },
	        "modes": {
	            "remove": {
	                "particles_nb": 10
	            }
	        }
	    }
	}} />

    )
}

export default Background

