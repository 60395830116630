import React,{useState} from 'react'
import Style from "../../Styles/Home.module.scss"
import {Link} from "react-router-dom" 


import Vid from "../../assets/images/Videos.JPG";
import Show from "../../assets/images/Bookings.JPG";
import Book from "../../assets/images/Showreel.JPG";

import fb from "../../assets/images/Facebook.JPG";
import play from "../../assets/images/Play.JPG";
import insta from "../../assets/images/Instagram.JPG";

import left from "../../assets/images/Left.JPG";
import right from "../../assets/images/Right.JPG";
import clickSound from "../../assets/Sounds/Click.mp3"


function Select() {

    let [showArrow, setShowArrow] = useState(Style.remove);
    const [vidArrow, setVidArrow] = useState(Style.remove);
    const [bookArrow, setBookArrow] = useState(Style.remove);

    let audio = new Audio(clickSound);
    let nope = "Image not available please try refresh"

    return (
        <div>
            
<div style={{display:"flex"}}>
<div style={{margin:"0 auto", width:"500px", position:"relative"}} 

onMouseOver={()=>{
    setBookArrow(Style.show)
}}
onMouseEnter={()=>{
    audio.play();
}}
onMouseLeave={()=>{
    setBookArrow(Style.remove)
}}>

            <img src={left} style={{position:"absolute", left:"0px"}} className={bookArrow} alt={nope} />
            <Link style={{textDecoration:"none"}} to="/Bookings">
   <img src={Show} style={{textAlign:"center",position:"relative" ,left:"40px",}} className={Style.select} alt={nope} />
        </Link>    <img src={right} style={{position:"absolute", right:"0px"}} className={bookArrow} alt={nope} />
</div>
</div>
<div style={{display:"flex"}}>
<div style={{margin:"0 auto", width:"500px", position:"relative"}}

onMouseOver={()=>{
    setShowArrow(Style.show)
}}
onMouseEnter={()=>{
    audio.play();
}}
onMouseLeave={()=>{
    setShowArrow(Style.remove)
}}>
            <img src={left} style={{position:"absolute", left:"0px"}} className={showArrow} alt={nope} />
      <Link style={{textDecoration:"none"}} to="/Showreel"> 
            <img src={Book} style={{textAlign:"center", position:"relative" ,left:"30px",}}
             className={Style.select} alt={nope} /></Link> 
            <img src={right} style={{ position:"absolute", right:"0px"}} className={showArrow} alt={nope} />
            </div>
            </div>

<div style={{display:"flex"}}>
<div style={{margin:"0 auto", width:"500px", position:"relative"}}

onMouseOver={()=>{
    setVidArrow(Style.show)

}}
onMouseEnter={()=>{
    audio.play();
}}
onMouseLeave={()=>{
    setVidArrow(Style.remove)
}}
>
            <img src={left} style={{position:"absolute", left:"0px"}} className={vidArrow} alt={nope} />
     
      <Link style={{textDecoration:"none"}} to="/Videos">
            <img src={Vid} style={{textAlign:"center", position:"relative" ,left:"85px"}} className={Style.select} alt={nope} />
         </Link> 

         <img src={right} style={{position:"absolute", right:"0px"}} className={vidArrow} alt={nope} />

</div>
        </div>

<div style={{display:"flex"}}>
<div style={{margin:"0 auto", position:"relative", top:"40px"}}>


       <a href="https://www.facebook.com/filmboiabes"  target="_blank" rel="noopener noreferrer" 
       style={{textDecoration:"none"}}> <img src={fb} style={{textAlign:"center"}}
        className={Style.icon} alt={nope} /></a>    
       <a href="https://www.youtube.com/watch?v=VYKPX1W3904&fbclid=IwAR28-Di3SREw20FF2MNC6gRvqVtnZX3csZUJ4qg3PhLiPPYr6pgmy0whUm4&ab_channel=MetzJnr"
        style={{textDecoration:"none"}} target="_blank" rel="noopener noreferrer">     
         <img src={play} style={{textAlign:"center"}} className={Style.icon}  alt={nope} />
      </a>  
      <a href="https://www.instagram.com/filmboiabes/?fbclid=IwAR040C5MvANs7p3LCwg-eEDcd-edBfKXdtBtdczu2vpeYrxS99MSGYViZq0"
      style={{textDecoration:"none"}}  target="_blank" rel="noopener noreferrer">
      <img src={insta} style={{textAlign:"center"}} className={Style.icon}  alt={nope} /></a>
<br/>
<br/>
<br/>
</div>
</div>
        </div>
    )
}

export default Select
