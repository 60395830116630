import React, {useState, useEffect, useContext} from 'react';
// import Header from "../Header/Header"
import Form from "./Form"
// import Recap from "./Rekapcha"
// import Map from "./Map"
// import Image from "../../assets/img/pic-6.jpg"
import Style from "../Styles/Home.module.scss"

import Paper from "@material-ui/core/Paper"
// import Logo from "../../assets/Logo.jpeg"
 
import Header from "../Nav/Nav"


function Contact() {

    // const [ flip, setFlip] = useState(false);

    //     const cntrlEnter = (e) => {     
    //         if(e.keyCode===13 || e.ctrlKey ) { flipy(); }
    //             }
        
           
// const flipy = () => { 
//                 if (flip===false){setFlip(true);alert("content now editable") } 
//                 else {setFlip(false); alert("content now un'editable \n wont save edits yet (no point till ssr/ production sorry)")}}

    // useEffect(()=>{
    //     // document.contentEditable()
    //     document.addEventListener('keypress', cntrlEnter );

    //     return (
    //    () =>{ document.removeEventListener('keypress', cntrlEnter)})

    // },[flip])


    // const recaptchaRef = React.useRef();

    let filler = "(unsure why image is not here) \n\n"

    return (
        <div 
        suppressContentEditableWarning={true} 
        // contentEditable={flip}
        >

            <Header/>       
            <br/>
             <div style={{display:"flex", flexWrap:"wrap"}}>

            <Paper elevation={3} style={{ padding:"20px", borderRadius:"20px",
             maxWidth:"1000px", margin:"0 auto", display:"flex", flexWrap:"wrap"}}>
                <container style={{maxWidth:"1500px"}}>
            <h1 style={{color:"orange"}}>Contact</h1>
{/* <p><strong>Tel</strong>: ?<br /> */}
<strong>Number</strong>: 010001999<br />
<strong>Email</strong>:FilmBoi Abes@dat shit<br />

{/* <strong>
    Address</strong>
    : 15 abby cresent, Harborne Birmingham, B?????</p> */}
    <br/><div style={{display:"flex", flexWrap:"wrap"}}>
    <Form/>
{/* <Recap/> */}

    <br/>
<div style={{maxWidth:"360px", fontWeight:"bold", color:"darkorange", margin:"0 auto"}}>
    <br/>
    <br/>
    <p>BGive me a shout.</p>
        
<p>Ill try get back soon,</p>
    <br/>
    <div style={{ margin:"0 auto", position:"relative", right:"11.5px"}}>
    {/* <img className={Style.image} src={Logo} style={{maxWidth:"360px", color:"green", margin:"0 
    auto"}} alt="Logo?"/>*/}
    </div> 
    <br/>
    <br/>
{/* <img  className={Style.image} src={Image} alt={filler}/>    <br/> */}
    <br/>
    <br/>
    </div>
</div>        

            </container>

</Paper>

        </div><br/>
{/* <Map  className={Style.image} /> */}

<br/></div>
    )
}

export default Contact
