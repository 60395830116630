import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import Button from "@material-ui/core/Button"


function Youtubez() {

    const [video, setvideo] = useState(1)
    // const [selection, setSelection] = useState("VYKPX1W3904")

let choices = ({
Metz: "VYKPX1W3904",
HiGrade:"xnC2IO5b3u0",
MykiTuff:"gfe1lSwrljY"
})

function onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const opts = {
    height: '437',
    width: '100%',
    playerVars: { 
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      start: 0,
    }
  };

  let selection;
  if (video ===1) {
selection = <div>
      <YouTube

videoId={choices.HiGrade}
opts={opts}
onReady={onReady}
/>
</div>
  } else if (video ===2) {

    selection = <div>
        <YouTube

videoId={choices.Metz}
opts={opts}
onReady={onReady}
/>

        </div>
  } else if (video ===3){
      selection =<div>
<YouTube

videoId={choices.MykiTuff}
opts={opts}
onReady={onReady}
/>
      </div>

  }


 
    return (
        <div >

<Button onClick={()=>{
    setvideo(1)
}}>first</Button>
<Button onClick={()=>{
    setvideo(2)
}}>Second</Button>
<Button onClick={()=>{
    setvideo(3)
}}>Third</Button>

{selection}

        </div>
    )


}

export default Youtubez
